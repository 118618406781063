import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';
import regeneratorRuntime from "regenerator-runtime";
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/font-awesome.min.css';
import '../css/app.css';
// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';

var baseURL = "https://api.buddyfinance.in/app/v1/";
var mode = 'production';
var app_version = '1.0.0';
var app = new Framework7({
  root: '#app', // App root element
  id: 'rbs.buddyfinance.in', // App bundle ID
  name: 'Buddy Finance', // App name
  theme: 'auto', // Automatic theme detection
  view: {
    pushState: true,
    pushStateSeparator: '#!',
    pushStateOnLoad: true,
  },
  //app pushstate
  smartSelect: {
    closeOnSelect: true,
  },
  sheet: {
    closeByBackdropClick: false
  },
  // App root methods
  methods: {
    initImageUpload: function(box) {
      let uploadField = box.querySelector('.image-upload');
        
        uploadField.addEventListener('change', getFile);
      
        function getFile(e){
          let file = e.currentTarget.files[0];
          checkType(file);
        }
        
        function previewImage(file){
          let thumb = box.querySelector('.js--image-preview'),
              reader = new FileReader();
      
          reader.onload = function() {
            thumb.style.backgroundImage = 'url(' + reader.result + ')';
          }
          reader.readAsDataURL(file);
          thumb.className += ' js--no-default';
        }
      
        function checkType(file){
          let imageType = /image\/.*|application\/pdf/;
          //var size=(file.size)/1000;
          if (!file.type.match(imageType)) {
            app.toast.show({
              text: 'Invalid file format!',
              closeTimeout: 3000,
            });
            throw 'Invalid file format';
          } else if (!file){
            app.toast.show({
              text: 'No file found!',
              closeTimeout: 3000,
            });
            throw 'No file found';
          } else {
            previewImage(file);
          }
        }
    },
    initDropEffect: function(box) {
      let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
          
      // get clickable area for drop effect
      area = box.querySelector('.js--image-preview');
      area.addEventListener('click', fireRipple);
      
      function fireRipple(e){
        area = e.currentTarget
        // create drop
        if(!drop){
          drop = document.createElement('span');
          drop.className = 'drop';
          this.appendChild(drop);
        }
        // reset animate class
        drop.className = 'drop';
        
        // calculate dimensions of area (longest side)
        areaWidth = getComputedStyle(this, null).getPropertyValue("width");
        areaHeight = getComputedStyle(this, null).getPropertyValue("height");
        maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));
    
        // set drop dimensions to fill area
        drop.style.width = maxDistance + 'px';
        drop.style.height = maxDistance + 'px';
        
        // calculate dimensions of drop
        dropWidth = getComputedStyle(this, null).getPropertyValue("width");
        dropHeight = getComputedStyle(this, null).getPropertyValue("height");
        
        // calculate relative coordinates of click
        // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
        x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
        y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
        
        // position drop and animate
        drop.style.top = y + 'px';
        drop.style.left = x + 'px';
        drop.className += ' animate';
        e.stopPropagation();
        
      }
    },
    initFileUpload: function() {
      var inputs = document.querySelectorAll( '.inputfile' );
        Array.prototype.forEach.call( inputs, function( input )
        {
          var label	 = input.nextElementSibling,
            labelVal = label.innerHTML;

          input.addEventListener( 'change', function( e )
          {
            var fileName = '';
            if( this.files && this.files.length > 1 )
              fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
              fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
              label.querySelector( 'span' ).innerHTML = fileName;
            else
              label.innerHTML = labelVal;
          });
        });
    },
    getPIN: function(pincode, success, error) {
      var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://pincode.p.rapidapi.com/",
        "method": "POST",
        "headers": {
          "x-rapidapi-host": "pincode.p.rapidapi.com",
          "x-rapidapi-key": "d52c228c20msh28eb4e495fba18ep1836f6jsn44bce943016f",
          "content-type": "application/json",
          "accept": "application/json"
        },
        "processData": false,
        "data": "{\"searchBy\":\"pincode\",\"value\":"+pincode+"}"
      }
      
      $.ajax(settings).done(function (response) {
        if(response.length === 0) {
          error("no places found");
        }
        else {
          success(response);
        }
      });
    },
    getJSON: function( url, callback) {
      $.getJSON(url, function(data) {
        callback(data);
      });
    },
    request: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    post: function( url, data, datatype, beforesend, success, error) {
      $.ajax({
        url: url,
        data: data,
        cache: false,
        dataType: datatype,
        type: 'POST',
        mimeType: "multipart/form-data",
        contentType: false,
        processData: false,
        crossDomain : true,
        beforeSend: function() {
          beforesend();
        },
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getUserData: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {userinfo: true, token: localStorage.getItem('authToken'), 'uuid': localStorage.getItem('uuid')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          $$('.reload-icon').addClass('rotate');
        },
        success: function(_response){
          $$('.reload-icon').removeClass('rotate');
          success(_response);
        },
        error: function(_error){
          $$('.reload-icon').removeClass('rotate');
        }
      });
    },
    getUserDataLoan: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {loaninfo: true, token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {
          $$('.reload-icon').addClass('rotate');
        },
        success: function(_response){
          $$('.reload-icon').removeClass('rotate');
          success(_response);
        },
        error: function(_error){
          $$('.reload-icon').removeClass('rotate');
        }
      });
    },
    getBank: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getbank: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getSettings: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getSettings: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    getAPI: function(success, error) {
      $.ajax({
        url: baseURL+'ajaxAuthentication.php',
        data: {getAPI: true},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        beforeSend: function() {},
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    saveAgreement: function(success, error) {
      var data = $$('#agreement-popup .page-content .block').html();
      $.ajax({
        url: baseURL+'saveAgreement.php',
        data: {token: localStorage.getItem('authToken')},
        cache: false,
        dataType: 'json',
        type: 'POST',
        crossDomain : true,
        success: function(_response){
          success(_response);
        },
        error: function(_error){
          error(_error);
        }
      });
    },
    random_color: function() {
      var letters = '0123456789ABCDEF';
      var color = '';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    addComma: function(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    getMaxloan: function(income) {
      if(income <= 15000) {
        return 100000;
      }
      else if(income > 15000 && income <= 30000) {
          return 200000;
      }
      else if(income > 30000 && income <= 50000) {
          return 300000;
      }
      else if(income > 50000 && income <= 75000) {
          return 400000;
      }
      else if(income > 75000 && income <= 100000) {
          return 500000;
      }
      else if(income > 100000 && income <= 300000) {
          return 1000000;
      }
      else if(income > 300000 && income <= 500000) {
          return 2000000;
      }
      else if(income > 500000) {
          return 2500000;
      }
    }
  },
  // App routes
  routes: routes,



  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    overlay: Framework7.device.cordova && Framework7.device.ios || 'auto',
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        //push notifications
        document.addEventListener('deviceready', OneSignalInit, false);
        function OneSignalInit() {
            // Uncomment to set OneSignal device logging to VERBOSE  
            // window.plugins.OneSignal.setLogLevel(6, 0);
            
            // NOTE: Update the setAppId value below with your OneSignal AppId.
            window.plugins.OneSignal.setAppId("2fe40210-220d-4294-a705-6cf4353845eb");
            window.plugins.OneSignal.setNotificationOpenedHandler(function(jsonData) {
              console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
            });
            //Prompts the user for notification permissions.
              //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
              window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
                console.log("User accepted notifications: " + accepted);
            });
            var deviceID = device.uuid;
            window.plugins.OneSignal.setExternalUserId(deviceID);
            localStorage.setItem('uuid', deviceID);
            //console.log(deviceID);
        }
        //handle backbutton
        document.addEventListener('backbutton', function (e) {
          //close action modal
          if ($$('.actions-modal.modal-in').length) {
            f7.actions.close('.actions-modal.modal-in');
            e.preventDefault();
            return false;
          }
          //close sheet modal
          if ($$('.sheet-modal.modal-in').length) {
            f7.sheet.close('.sheet-modal.modal-in');
            e.preventDefault();
            return false;
          }
          //close popup
          if ($$('.popup.modal-in').length) {
            if ($$('.popup.modal-in>.view').length) {
              const currentView = f7.views.get('.popup.modal-in>.view');
              if (currentView && currentView.router && currentView.router.history.length > 1) {
                currentView.router.back();
                e.preventDefault();
                return false;
              }
            }
            f7.popup.close('.popup.modal-in');
            e.preventDefault();
            return false;
          }
        })
        cordovaApp.init(f7);
      }
      //handle backbutton in browser
      // window.addEventListener('popstate', function(event) {
      //   alert( "triggered" );
      // });
    },
    //APP PAGE INIT
    pageInit: function () {
      var f7 = this;
      if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7);
      }
      if(mode == 'production') {
        //disable view in windows
        if ($(window).width() > 600) {
          $$('.page-content').empty();
          $$(".page-content").append('<div class="block text-align-center">\
          <i class="f7-icons text-color-red" style="font-size:100px">exclamationmark_triangle_fill</i>\
          <div class="fw-600 text-color-red m-auto" style="font-size: 18px; width: 40%;">We want to make your experience as smooth as possible! Right now, our website isn\'t available in desktop mode. Please use your mobile browser for the best experience.</div>\
          </div>');
          $$('.navbar').empty();
          $$('.title').html('');
          $$('.title-large-text').html('');
          $$('.panel-open').empty();
        }
        $(window).resize(function() {
          windowResize()
        });
        //disable f12 and right click
        $(document).keydown(function (event) {
          if (event.keyCode == 123) { // Prevent F12
              return false;
          } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
              return false;
          }
        });
        $(document).on("contextmenu", function (e) {        
          e.preventDefault();
        });
      }
      //print current year
      var date = new Date();
      var res = date.getFullYear();
      $$('.getCurrYear').html(res);
      $$('.appVersion').html(app_version);
      //logout
      $$(document).off('click', '.logout');
      $$(document).on('click', '.logout' ,function() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('phone');
        localStorage.removeItem('isloggedIN');
        localStorage.removeItem('amount');
        localStorage.removeItem('payment');
        localStorage.removeItem('phone');
        localStorage.removeItem('maxloan');
        app.popup.close('#profile-popup');
        var router = app.views.current.router;
        //view.router.navigate('/login/');
        router.navigate(router.currentRoute.url, {
          reloadCurrent: true,
          ignoreCache: true,
        });
        $$('.profile-name').html('Login/Signup');
        $$('.login-link').attr('href','/login/');
        $$('.login-link').removeClass('logout');
        $$('.login-link').html('Login');
        $$('.profile-link').attr('href','/login/');
        $$('.profile-link').removeClass('popup-open');
        $$('.profile-link').removeAttr('data-popup');
        f7.toast.show({
          text: 'Logged out from this device!',
          closeTimeout: 3000,
        });
      })
      //save agreement
      // $$('.confirm-signature').on('click', ()=> {
      //   f7.preloader.show();
      //   f7.methods.saveAgreement((success) => {
      //     if(success.status == 'success') {
      //       f7.preloader.hide();
      //       f7.views.main.router.navigate({name: 'wallet'});
      //       f7.popup.close();
      //     }
      //   }, (error) => {
      //     f7.preloader.hide();
      //     f7.dialog.alert('Something Went Wrong. PLease try again');
      //   });
      // });
      //OTP verification
      $(document).off('click', '.acBTNotp');
      $(document).on('click','.acBTNotp',function(){
        if (!$$('#ac-otp')[0].checkValidity()) {
          console.log('Check Validity!');
        } else {
          OTPverification();
        }
      });
      function OTPverification() {
        var value = $$('#ac-otp').val();
        f7.methods.request(
          baseURL+'/ajaxAuthentication.php',
          {OTPver: true, mobile: localStorage.getItem('phone'), OTP: value},
          'json',
          function() {f7.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
          function(data) {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            if(data.status == 'success') {
              f7.toast.show({
                text: 'Logged in successfully!',
                closeTimeout: 3000,
              });
              f7.popup.close('.sheet-OTP--verification');
              localStorage.setItem('authToken', data.token);
              localStorage.setItem('isloggedIN', true);
              setTimeout(() => {
                var view = f7.views.current;
                view.router.navigate('/');
                f7.dialog.close();
              }, 1000);
            }
            else {
              f7.toast.show({
                text: data.msg,
                closeTimeout: 3000,
              });
            }
          },
          function() {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            f7.toast.show({
              text: 'No internet connection!',
              closeTimeout: 3000,
            });
          }
        );
      }
      //resend otp
      $(document).off('click', '.resendOTPac');
      $(document).on('click', '.resendOTPac', function() {
        var type = $$(this).data('type');
          if(type == 'account') {
            var phone = localStorage.getItem('phone');
          } else if(type == 'forgetpass') {
            var phone = $$('#fp-username').val();
          } else {
            
          }
          f7.methods.request(baseURL+'/ajaxAuthentication.php',
        {OTPresend: true, phone: phone, type: type},
        'json',
        function() {f7.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
        function(data) {
          $$('.button').removeClass('disabled');
          f7.dialog.close();
          if(data.status == 'success') {
            f7.toast.show({
              text: data.msg,
              closeTimeout: 3000,
            });
          }
          else {
            f7.toast.show({
              text: data.msg,
              closeTimeout: 3000,
            });
          }
        },
        function() {
          $$('.button').removeClass('disabled');
          f7.dialog.close();
          f7.toast.show({
            text: 'Failed to Connect',
            closeTimeout: 3000,
          });
        }
        );
      });
      //not editable data dialog
      $(document).off('click', '.not__editable');
      $(document).on('click', '.not__editable', function() {
        f7.dialog.alert('If you want to change this information, please contact our support team.');
      });
      //edit profile
      $(document).off('click', '.editProfile');
      $(document).on('click', '.editProfile', function() {
        f7.popup.open('#editProfile-popup');
        f7.popup.close('#profile-popup');
      });
      //save data
      $(document).off('click', '.update__profile');
      $(document).on('click', '.update__profile', function() {
        if (!$$('#editProfile')[0].checkValidity()) {
          console.log('Check Validity!');
        } else {
          editProfileSave();
        }
      });
      //user login
      function editProfileSave() {
        var formdata = $('#editProfile').serialize();
        var data = formdata+'&editProfileSave=true&token='+localStorage.getItem('authToken');
        f7.methods.request(
          baseURL+'/ajaxAuthentication.php',
          data,
          'json',
          function() { f7.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
          function(data) {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            if(data.status == 'success') {
              f7.popup.close('#editProfile-popup');
              f7.methods.getUserData(function(data) {
                $$('.profile-name').html(data.name);
              });
              f7.toast.show({
                text: 'Profile information updated!',
                closeTimeout: 3000,
              });
            }
            else {
              f7.toast.show({
                text: data.msg,
                closeTimeout: 3000,
              });
            }
          },
          function() {
            $$('.button').removeClass('disabled');
            f7.dialog.close();
            f7.toast.show({
              text: 'No internet connection!',
              closeTimeout: 3000,
            });
          }
        );
      }
      $$('#terms-popup, #policy-popup').on('popup:open', function (e, popup) {
        f7.popup.close('#about-popup');
      });
      function windowResize() {
        if ($(window).width() > 600) {
          $$('.page-content').empty();
          $$(".page-content").append('<div class="block text-align-center">\
          <img class="" src="assets/icons/no-pc.png" width="350"/>\
          <div class="fw-600 text-color-red m-auto" style="font-size: 18px; width: 40%;">We want to make your experience as smooth as possible! Right now, our website isn\'t available in desktop mode. Please use your mobile browser for the best experience.</div>\
          </div>');
          $$('.navbar').empty();
          $$('.title').html('');
          $$('.title-large-text').html('');
          $$('.panel-open').empty();
        } else {
          //location.reload();
        }
      }
    },
  },
});
