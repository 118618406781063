import $$ from 'dom7';

import HomePage from '../pages/home.f7.html';
import LoginPage from '../pages/login.f7.html';
import RegisterPage from '../pages/register.f7.html';
import PaymentPage from '../pages/payment.f7.html';
import FormPage1 from '../pages/form1.f7.html';
import BusinessLoanPage from '../pages/businessloan.f7.html';
import FormPage2 from '../pages/form2.f7.html';
import FormPage4 from '../pages/form4.f7.html';
import BeneficiaryPage from '../pages/beneficiary.f7.html';
import loanStatusPage from '../pages/status.f7.html';

import NotFoundPage from '../pages/404.f7.html';
import party from "party-js";
import upiqr from "upiqr";
import 'animate.css';
var baseURL = "https://api.buddyfinance.in/app/v1/";
const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var routes = [
  {
    path: '/',
    component: HomePage,
    name: 'Home',
    on: {
      pageInit: function (e, page) {
        var app = this.app;
        //get helpline Number
        app.methods.getSettings(function(data) {
          $$('.helpLinenumber').html(data.help);
          $$('.helpLinenumber-href').attr('href','tel:'+data.help);
        });
        $$('.contact_link').click(function(){
          app.dialog.alert('Get in touch with us to find answers to your queries related to loans, cards, insurance and other services. Please mail us at support@buddyfinance.in');
        }) 
        //swiper slider
        var swiper = app.swiper.create('.swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 0,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });
        app.methods.getUserData(function(data) {
          if(data.isLoggedin == 'true') {
            app.dialog.close();
            $$('.profile-name').html(data.name);
            $$('.loggedinAvatar').attr('src','https://ui-avatars.com/api/?name='+data.name+'&background=00488E&rounded=true&color=FFFFFF&size=40');
            $$('.profile-email').html(data.email);
            $$('.profile-phone').html('+91-'+data.phone);
            $$('.profile-address').html(data.address);
            $$('.profile-dob').html(data.dob);
            $$('.welcome__msg').removeClass('hidden');
            $$('.login-link').attr('href','#');
            $$('.login-link').addClass('logout');
            $$('.login-link').html('Logout');
            $$('.profile-link').attr('href','#');
            $$('.profile-link').addClass('popup-open');
            $$('.profile-link').attr('data-popup','#profile-popup');
            if(data.loanStatus == true) { //generate loan status
              $$('.emi-link').attr('href','#');
              $$('.emi-link').addClass('popup-open');
              $$('.emi-link').attr('data-popup','#emi-popup');
              $$('.cart-link').remove();
              app.methods.request(
                baseURL+'getEMI.php',
                {token: localStorage.getItem('authToken')},
                'html',
                function() {
                  app.dialog.progress('Loading...');
                },
                function(data) {
                  app.dialog.close();
                  $$('#emi-popup .emi__block').html(data);
                },
                function() {
                  app.dialog.close();
                  app.toast.show({
                    text: 'No internet connection!',
                    closeTimeout: 3000,
                  });
                }
              );
              app.methods.getUserDataLoan(function(data) {
                if(data.applied == true && data.approve != true) {
                  // $$('.overlay__bg').removeClass('hidden');
                  $$('.card__applyLoanLink').attr('href','/form1/');
                  $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
                  // $$('.card__applyLoanTITLE').html('<b>KYC Submited</b>');
                  // $$('.card__applyLoanTXT').html('You alreday applied for a loan, check your loan status');
                  // $$('.card__applyLoanICON').attr('src', 'assets/icons/verification.svg');
                }
                if(data.applied == true && data.approve == true) {
                  // $$('.overlay__bg').removeClass('hidden');
                  $$('.card__applyLoanLink').attr('href','/form1/');
                  $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
                  // $$('.card__applyLoanTITLE').html('<b>KYC Approved</b>');
                  // $$('.card__applyLoanTXT').html('You alreday applied for a loan, check your loan status');
                  // $$('.card__applyLoanICON').attr('src', 'assets/icons/verification.svg');
                }
              })
            } else {
              $$('.emi-link').attr('href','#');
              $$('.emi-link').addClass('popup-open');
              $$('.emi-link').attr('data-popup','#emi-popup');
              $$('.card__applyLoanLink').attr('href','/form1/');
              $$('.card__applyLoanLinkBusiness').attr('href','/businessloan/');
              $$('.card__applyLoanTXT').html('You can now apply for a loan');
            }
          } else {
          }
        });
        //init awiper
        var mySwiper = app.swiper.create('.swiper-container', {
          autoplay: {
            delay: 5000,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'preloaderbar',
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      }
    }
  },
  {
    path: '/login/',
    component: LoginPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'true') {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        $$('.lgNum').on('click',function(){
          if (!$$('#userLogin')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            login();
          }
        });
        //user login
        function login() {
          var formdata = $('#userLogin').serialize();
          var data = formdata+'&checkLogin=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() { app.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('phone', data.phone);
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
              }
              else if(data.code == 'deactivated') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else if(data.code == 'notverified') {
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //forget password
        $(document).off('click', '.fpBTN');
        $(document).on('click','.fpBTN',function(){
          if (!$$('#fp-username')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            Forgetpassword();
          }
        });
        function Forgetpassword() {
          var phone = $$('#fp-username').val();
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            {forgetPass: true, mobile: phone},
            'json',
            function() {app.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                app.popup.close('.sheet-forget--pass');
                app.popup.open('.sheet-forget--otp');
                localStorage.setItem('phone', data.phone);
                $$('.sheet-forget--otp--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //change password
        $(document).off('click', '.fpBTNotp');
        $(document).on('click','.fpBTNotp',function(e){
          e.preventDefault();
          if (!$$('#changePass')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            changePass();
          }
        });
        function changePass() {
          var formdata = $('#changePass').serialize();
          var data = formdata+'&phone='+localStorage.getItem('phone')+'&changePass=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() {app.dialog.progress('Loading...'); $$('.button').addClass('disabled');},
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                app.popup.close('.sheet-forget--otp');
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
      }
    }
  },
  {
    path: '/register/',
    component: RegisterPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.methods.getUserData(
          function(data) {
            if(data.isLoggedin == 'true') {
              app.views.main.router.navigate({name: 'Home'});
            }
          }
        );
        var input = document.querySelectorAll('#dob')[0];
        var dateInputMask = function dateInputMask(elm) {
          elm.addEventListener('keypress', function(e) {
            if(e.keyCode < 47 || e.keyCode > 57) {
              e.preventDefault();
            }
            
            var len = elm.value.length;
            
            // If we're at a particular place, let the user type the slash
            // i.e., 12/12/1212
            if(len !== 1 || len !== 3) {
              if(e.keyCode == 47) {
                e.preventDefault();
              }
            }
            
            // If they don't add the slash, do it for them...
            if(len === 2) {
              elm.value += '/';
            }
        
            // If they don't add the slash, do it for them...
            if(len === 5) {
              elm.value += '/';
            }

            //validate date
            if(len === 10) {
              var date = elm.value.split('/');
              var d = new Date(date[2], date[1] - 1, date[0]);
              if(d && (d.getMonth() + 1) == date[1] && d.getDate() == Number(date[0]) && d.getFullYear() == date[2]) {
                elm.setCustomValidity('');
              } else {
                elm.setCustomValidity('Please enter a valid date');
              }
            }
          });
        };
        dateInputMask(input);
        // var calendarDefault = app.calendar.create({
        //   inputEl: '#dob',
        //   closeOnSelect: true,
        //   dateFormat: 'dd/mm/yyyy',
        //   header: false
        // });
        //user registration
        $$('.btnSignup').on('click',function(){
          if (!$$('#userSignup')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            signup();
          }
        });
        //user signup
        function signup() {
          var formdata = $('#userSignup').serialize();
          var data = formdata+'&userSignup=true';
          app.methods.request(
            baseURL+'/ajaxAuthentication.php',
            data,
            'json',
            function() { app.dialog.progress('Loading...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('phone',data.phone);
                app.popup.open('.sheet-OTP--verification');
                $$('.sheet-OTP--verification--phonenumber').html('xxxxxxx'+data.phone.slice(-3));
              }
              else if(data.code == 'exist') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
      }
    }
  },
  {
    path: '/payment/',
    component: PaymentPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // initialize box-scope
        var boxes = document.querySelectorAll('.box');

        for(let i = 0; i < boxes.length; i++) {
          let box = boxes[i];
          app.methods.initDropEffect(box);
          app.methods.initImageUpload(box);
        }
        app.methods.initFileUpload();
        app.methods.getUserData(function(data) {
          localStorage.setItem('payment','form5');
          app.methods.getUserDataLoan(function(data) {
            $$('.fees_amount').html('₹'+data.cardFees);
            app.dialog.close();
          });
        })
        app.dialog.progress('Loading...');
        $$('.getBankdet').empty();
        app.methods.getBank(function(data) {
          upiqr({
            payeeVPA: data.upi_id,
            payeeName: data.upi_name,
            transactionNote: 'Purchasing a Vanquis Card',
            amount: $$('.fees_amount').val(),
          })
          .then((upi) => {
            $$('.upiQR').attr('src', upi.qr);
            $$('.upiIntent').attr('href', upi.intent);
          })
          .catch(err => {
            console.log(err);
          });
          $$('.upi__id').html(data.upi_id);
          $$('.getBankdet').append('<div class="card card-main" style="margin-top: 25px;">\
            <div class="card-content">\
              <div class="list list__loanst">\
                <ul class="color-logo">\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-gray f-12"> <b>Beneficiary Name</b> </div>\
                        <div class="item-after text-color-blue f-12">'+data.acc_name+'</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-gray f-12"><b>Account Number</b></div>\
                        <div class="item-after text-color-blue f-12">'+data.acc_num+'</div>\
                      </div>\
                    </div>\
                  </li>\
                  <li>\
                    <div class="item-content">\
                      <div class="item-inner">\
                        <div class="item-title text-color-gray f-12"><b>IFSC Code</b></div>\
                        <div class="item-after text-color-blue f-12">'+data.acc_ifsc+'</div>\
                      </div>\
                    </div>\
                  </li>\
                </ul>\
              </div>\
            </div>\
          </div>');
        });
        //copy upi id
        $$('.copy__upi').on('click', function() {
          var upiID = $$('.upi__id').html();
          upiID = upiID.split(': ')[1];
          var textArea = document.createElement("textarea");
          textArea.value = upiID;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          app.toast.show({
            text: 'UPI ID copied!',
            closeTimeout: 3000,
          });
        });
        //download QR
        $$('.downloadQR').on('click', function() {
          var qr = $$('.upiQR').attr('src');
          //base64 to image
          var img = new Image();
          img.src = qr;
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          img.onload = function() {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            var a = document.createElement('a');
            a.href = canvas.toDataURL('image/png');
            a.download = 'UPI_QR.png';
            a.click();
          }
        });
        //doc submit
        $$('#payment').on('change', function() {
          var paymentIMG = $$('#payment').prop('files')[0];
          var utr = $$('#utr').val();
          var formData = new FormData();
          formData.append('paymentForm', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('paymentIMG', paymentIMG);
          formData.append('utr', utr);
          var file = this.files[0];
          var fileType = file['type'];
          var validImageTypes = ["image/gif", "image/jpeg", "image/png"];
          if(!$$('#utr')[0].checkValidity()) {
            //set custom validity
            $$('input#utr')[0].setCustomValidity('Please enter UTR number');
          }
          else if ($.inArray(fileType, validImageTypes) < 0) {
            app.toast.show({
              text: 'This is not an Image!',
              closeTimeout: 3000,
            });
          }
          else {
            app.methods.post(
              baseURL+'ajaxKYC.php',
              formData,
              'json',
              function() {
                app.dialog.progress('Loading...');
              },
              function(data) {
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  $$('.sheet-loan--applied__title').html('Payment Submitted!');
                  $$('.sheet-loan--applied__text').html('Your payment has been submitted successfully. Our team will verify your payment and update your account.');
                  app.popup.open('.sheet-loan--applied');
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: data.status,
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                app.dialog.close();
                app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
              }
            );
          }
      });
      }
    }
  },
  {
    path: '/form1/',
    component: FormPage1,
    name: 'form1',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          //var num = '₹' + range.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var num = formatter.format(range.value);
          $$('.laon__amount').html(num);
          $$('.applyLoan').attr('data-amount', range.value);
          if(range.value >= 50000 && range.value <= 150000) {
            $$('.laon__amountTEN').html('6 - 12 Months');
          } else if(range.value >= 150000 && range.value <= 250000) {
            $$('.laon__amountTEN').html('6 - 18 Months');
          } else if(range.value >= 250000 && range.value <= 350000) {
            $$('.laon__amountTEN').html('6 - 24 Months');
          } else {
            $$('.laon__amountTEN').html('6 - 36 Months');
          }
        });
        // $$('#amount').on('keyup', function() {
        //   var range = app.range.get('.amount-range');
        //   var value = $$('#amount').val();
        //   range.setValue(value);
        // });
        // $$('#amount').on('focusout', function() {
        //   var val = $$('#amount').val();
        //   if(val < 50000) {
        //     $$('#amount').val(50000);
        //   }
        //   else if (val > 500000) {
        //     $$('#amount').val(500000);
        //   }
        // });
        $$('.applyLoan').off('click');
        $$('.applyLoan').on('click',function(){
          var amount = $(this).attr('data-amount');
          localStorage.setItem('maxloan', amount);
          console.log(amount);
          app.views.main.router.navigate('/form2/');
        })
      }
    }
  },
  {
    path: '/businessloan/',
    component: BusinessLoanPage,
    name: 'businessloan',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        //range slider
        $$('.amount-range').on('range:change', function (e, range) {
          //var num = '₹' + range.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var num = formatter.format(range.value);
          $$('.laon__amount').html(num);
          $$('.applyLoan').attr('data-amount', range.value);
          if(range.value >= 50000 && range.value <= 150000) {
            $$('.laon__amountTEN').html('6 - 12 Months');
          } else if(range.value >= 150000 && range.value <= 250000) {
            $$('.laon__amountTEN').html('6 - 18 Months');
          } else if(range.value >= 250000 && range.value <= 350000) {
            $$('.laon__amountTEN').html('6 - 24 Months');
          } else {
            $$('.laon__amountTEN').html('6 - 36 Months');
          }
        });
        // $$('#amount').on('keyup', function() {
        //   var range = app.range.get('.amount-range');
        //   var value = $$('#amount').val();
        //   range.setValue(value);
        // });
        // $$('#amount').on('focusout', function() {
        //   var val = $$('#amount').val();
        //   if(val < 50000) {
        //     $$('#amount').val(50000);
        //   }
        //   else if (val > 500000) {
        //     $$('#amount').val(500000);
        //   }
        // });
        $$('.applyLoan').off('click');
        $$('.applyLoan').on('click',function(){
          var amount = $(this).attr('data-amount');
          localStorage.setItem('maxloan', amount);
          console.log(amount);
          app.views.main.router.navigate('/form4/');
        })
      }
    }
  },
  {
    path: '/form2/',
    component: FormPage2,
    name: 'form2',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // initialize box-scope
        var boxes = document.querySelectorAll('.box');

        for(let i = 0; i < boxes.length; i++) {
          let box = boxes[i];
          // app.methods.initDropEffect(box);
          app.methods.initImageUpload(box);
        }
        //check if applied or not
        app.methods.getUserDataLoan(function(data) {
          if(data.applied == true) {
            $$('.is__KYClist').removeClass('hidden');
            $$('.KYC__form').addClass('hidden');
            $$('.toolbar').addClass('hidden');
          }
        })
        //emi calculator
        computeLoan(6);
        function computeLoan(tenure) {
          var intRate = 12.99;
          var amount = localStorage.getItem('maxloan');
          var tenure = tenure;
          var r = intRate / (12 * 100);
          var emi = ((amount * r * Math.pow(1 + r, tenure)) /  
                        (Math.pow(1 + r, tenure) - 1)).toFixed(2);
          $$('.monthlyEMI').html(formatter.format(emi));
        }
        $$(document).on('change','#tenure',function(){
          var tenure = this.value;
          computeLoan(tenure);
        });
        //filter API on/off
        app.methods.getAPI(function(data) {
          data.forEach(val => {
            if(val.name == 'aadhaar' && val.value == '0') {
              //remove aadhaar validation
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            }
            if(val.name == 'pan' && val.value == '0') {
              //remove pan validation
              $$('.panVerifyBtn').addClass('hidden');
              $$('.panVerifyLable').addClass('hidden');
              $$('#panVerify').val('false');
            }
            if(val.name == 'bank' && val.value == '0') {
              $$('.bankVerifyBtn').addClass('hidden');
              $$('.bankVerifyLable').addClass('hidden');
              $$('#bankVerify').val('false');
            }
          });
        });
        //change aadhaarVerifyLable
        $$(document).on('change','#kycDoc', function(){
          var doc = this.value;
          var isVerify = $$('#aadhaarVerify').val();
          console.log(isVerify);
          if(isVerify != true) {
            if(doc == 'Voter ID') {
              $$('.aadhaarVerifyLable').html('Please verify your Voter ID and make sure your registered name is the same as on your Voter ID.');
              $$('.aadhaarVerifyBtn').attr('data-doc','voter');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else if(doc == 'Driving License') {
              $$('.aadhaarVerifyLable').html('Please verify your Driving License and make sure your registered name and date of birth is the same as on your Driving License.');
              $$('.aadhaarVerifyBtn').attr('data-doc','driving');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else if(doc == 'Passport') {
              $$('.aadhaarVerifyLable').html('Please verify your Passport');
              $$('.aadhaarVerifyBtn').attr('data-doc','passport');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else {
              $$('.aadhaarVerifyLable').html('Please verify your Aadhaar card via UIDAI and make sure your phone number is linked with your Aadhaar.');
              $$('.aadhaarVerifyBtn').attr('data-doc','aadhaar');
            }
          }
        });
        //verify aadhaar card
        $$('.aadhaarVerifyBtn').on('click',function(){
          if (!$$('#aadhaar')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            var doc = $(this).attr("data-doc");
            if(doc == 'aadhaar') {
              verifyAadhaar();
            } else if(doc == 'voter') {
              verifyVoter();
            } else {
              verifyDriving();
            }
          }
        });
        //aadhaar verification
        function verifyAadhaar() {
          var aadhaar = $('#aadhaar').val();
          var data = 'aadhaar='+aadhaar+'&aadhaarVerify=true';
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Aadhaar Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('reference_id', data.reference_id);
                app.popup.open('.sheet-OTP--aadhaar');
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify otp for aadhaar
        $$('.adhOTPbtn').on('click',function(){
          if (!$$('#adh-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaarOTP();
          }
        });
        function verifyAadhaarOTP() {
          var otp = $('#adh-otp').val();
          var data = 'otp='+otp+'&reference_id='+localStorage.getItem('reference_id')+'&aadhaarVerifyOTP=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying OTP...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.removeItem('reference_id');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.aadhaarVerifyBtn').empty();
                $$('.aadhaarVerifyLable').empty();
                $$('.kyc__ver1').removeClass('hidden');
                $$('#aadhaarVerify').val('true');
                $$('#aadhaar').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //on keyup pan capitalize
        $$('#panNum').on('keyup', function() {
          var val = $$('#panNum').val();
          $$('#panNum').val(val.toUpperCase());
        });
        //verify pan card
        $$('.panVerifyBtn').on('click',function(){
          if (!$$('#panNum')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyPAN();
          }
        });
        function verifyPAN() {
          var pan = $('#panNum').val();
          var data = 'pan='+pan+'&panVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.panVerifyBtn').empty();
                $$('.panVerifyLable').empty();
                $$('.kyc__ver2').removeClass('hidden');
                $$('#panVerify').val('true');
                $$('#panNum').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify bank account
        $$('.bankVerifyBtn').on('click',function(){
          if (!$$('#bankacc')[0].checkValidity() && !$$('#ifsc')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyBank();
          }
        });
        function verifyBank() {
          var account_no = $('#bankacc').val();
          var ifsc = $('#ifsc').val();
          var data = 'account_no='+account_no+'&bankVerify=true+&ifsc='+ifsc+'&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Bank Account...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.bankVerifyBtn').empty();
                $$('.bankVerifyLable').empty();
                $$('.bankVerifyBtn').addClass('hidden');
                $$('.kyc__ver3').removeClass('hidden');
                $$('#bankVerify').val('true');
                $$('#bankacc').attr('readonly', true);
                $$('#ifsc').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          //capitalize ifsc
          var val = $$('#ifsc').val();
          $$('#ifsc').val(val.toUpperCase());
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html('Branch: '+data.BRANCH);
              $$('#branch').val(data.BRANCH);
              $$('#bankname').val(data.BANK);
              $$('.item-input-bank').addClass('item-input-focused');
              $$('.item-input-bank').addClass('item-input-with-value');
            });
          }
        })
        //input validation
        $$('.form2-submit').on('click', function() {
          var amount = localStorage.getItem('maxloan');
          var tenure = $$('#tenure').val();
          var addimg1 = $$('#addressimg1').prop('files')[0];
          var addimg2 = $$('#addressimg2').prop('files')[0];
          var pan = $$('#panNum').val();
          var panimg = $$('#panimg').prop('files')[0];
          var bankAcc = $$('#bankacc').val();
          var IFSC = $$('#ifsc').val();
          var aadhaar = $$('#aadhaar').val();
          var bankname = $$('#bankname').val();
          var docType = $$('#kycDoc').val();
          var branch = $$('#branch').val();
          var passbook = $$('#passbook').prop('files')[0];
          var selfie = $$('#selfie').prop('files')[0];

          var aadhaarVerify = $$('#aadhaarVerify').val();
          var panVerify = $$('#panVerify').val();
          var bankVerify = $$('#bankVerify').val();
          var formData = new FormData();
          
          formData.append('form2', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('amount', amount);
          formData.append('tenure', tenure);
          formData.append('aadhaar', aadhaar);
          formData.append('docType', docType);
          formData.append('addressimg1', addimg1);
          formData.append('addressimg2', addimg2);
          formData.append('pan', pan);
          formData.append('docType', docType);
          formData.append('panimg', panimg);
          formData.append('bankacc', bankAcc);
          formData.append('bankname', bankname);
          formData.append('ifsc', IFSC);
          formData.append('branch', branch);
          formData.append('passbook', passbook);
          formData.append('selfie', selfie);

          formData.append('verify1', aadhaarVerify);
          formData.append('verify2', panVerify);
          formData.append('verify3', bankVerify);
          var error = 0;
          for (var value of formData.values()) {
            if(value == '' || value == 'undefined') {
              error += 1;
            }
          }
          if(error == 0) {
            app.methods.post(
              baseURL+'ajaxKYC.php',
              formData,
              'json',
              function() {
                $$('.button').addClass('disabled');
                app.dialog.progress('Uploading...');
              },
              function(data) {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  if(aadhaarVerify == 'true' && panVerify == 'true' && bankVerify == 'true') {
                    app.popup.open('.sheet-loan--applied');
                    $$('.sheet-loan--applied__title').html('KYC Submitted Successfully!');
                    $$('.sheet-loan--applied__text').html('Your KYC and Bank account has been verified, please see your loan status for further information.');
                    localStorage.setItem('amount', data.amount);
                    var view = app.views.current;
                    view.router.back('/',{force:true});
                  } else {
                    app.popup.open('.sheet-loan--applied');
                    $$('.loan__approvedIMG').attr('src','assets/icons/file.gif');
                    $$('.sheet-loan--applied__title').html('KYC Submitted Successfully!');
                    $$('.sheet-loan--applied__text').html('Your KYC and Bank account has been submitted, please wait up to 24 hours while we\'re verifying your information.');
                    localStorage.setItem('amount', data.amount);
                    var view = app.views.current;
                    view.router.back('/',{force:true});
                  }
                }
                else if(data.errorcode == 'applied') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('KYC Already Submitted!');
                  $$('.sheet-loan--applied__text').html('You have already submitted your documents, please see your loan status for further information.');
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: 'Something went wrong, please try again',
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
              }
            );
          }
          else {
            $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Please complete all the fields and upload all the documents!',
                closeTimeout: 3000,
              });
          }
          
        });
      }
    }
  },
  {
    path: '/form4/',
    component: FormPage4,
    name: 'form4',
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        // initialize box-scope
        var boxes = document.querySelectorAll('.box');

        for(let i = 0; i < boxes.length; i++) {
          let box = boxes[i];
          // app.methods.initDropEffect(box);
          app.methods.initImageUpload(box);
        }
        //check if applied or not
        app.methods.getUserDataLoan(function(data) {
          if(data.applied == true) {
            $$('.is__KYClist').removeClass('hidden');
            $$('.KYC__form').addClass('hidden');
            $$('.toolbar').addClass('hidden');
          }
        });
        //emi calculator
        computeLoan(6);
        function computeLoan(tenure) {
          var intRate = 11.75;
          var amount = localStorage.getItem('maxloan');
          var tenure = tenure;
          var r = intRate / (12 * 100);
          var emi = ((amount * r * Math.pow(1 + r, tenure)) /  
                        (Math.pow(1 + r, tenure) - 1)).toFixed(2);
          $$('.monthlyEMI').html(formatter.format(emi));
        }
        $$(document).on('change','#tenure',function(){
          var tenure = this.value;
          computeLoan(tenure);
        });
        //filter API on/off
        app.methods.getAPI(function(data) {
          data.forEach(val => {
            if(val.name == 'aadhaar' && val.value == '0') {
              //remove aadhaar validation
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            }
            if(val.name == 'pan' && val.value == '0') {
              //remove pan validation
              $$('.panVerifyBtn').addClass('hidden');
              $$('.panVerifyLable').addClass('hidden');
              $$('#panVerify').val('false');
            }
            if(val.name == 'bank' && val.value == '0') {
              $$('.bankVerifyBtn').addClass('hidden');
              $$('.bankVerifyLable').addClass('hidden');
              $$('#bankVerify').val('false');
            }
          });
        });
        //change aadhaarVerifyLable
        $$(document).on('change','#kycDoc', function(){
          var doc = this.value;
          var isVerify = $$('#aadhaarVerify').val();
          console.log(isVerify);
          if(isVerify != true) {
            if(doc == 'Voter ID') {
              $$('.aadhaarVerifyLable').html('Please verify your Voter ID and make sure your registered name is the same as on your Voter ID.');
              $$('.aadhaarVerifyBtn').attr('data-doc','voter');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else if(doc == 'Driving License') {
              $$('.aadhaarVerifyLable').html('Please verify your Driving License and make sure your registered name and date of birth is the same as on your Driving License.');
              $$('.aadhaarVerifyBtn').attr('data-doc','driving');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else if(doc == 'Passport') {
              $$('.aadhaarVerifyLable').html('Please verify your Passport');
              $$('.aadhaarVerifyBtn').attr('data-doc','passport');
              $$('.aadhaarVerifyBtn').addClass('hidden');
              $$('.aadhaarVerifyLable').addClass('hidden');
              $$('#aadhaarVerify').val('false');
            } else {
              $$('.aadhaarVerifyLable').html('Please verify your Aadhaar card via UIDAI and make sure your phone number is linked with your Aadhaar.');
              $$('.aadhaarVerifyBtn').attr('data-doc','aadhaar');
            }
          }
        });
        //verify aadhaar card
        $$('.aadhaarVerifyBtn').on('click',function(){
          if (!$$('#aadhaar')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            var doc = $(this).attr("data-doc");
            if(doc == 'aadhaar') {
              verifyAadhaar();
            } else if(doc == 'voter') {
              verifyVoter();
            } else {
              verifyDriving();
            }
          }
        });
        //aadhaar verification
        function verifyAadhaar() {
          var aadhaar = $('#aadhaar').val();
          var data = 'aadhaar='+aadhaar+'&aadhaarVerify=true';
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Aadhaar Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.setItem('reference_id', data.reference_id);
                app.popup.open('.sheet-OTP--aadhaar');
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify otp for aadhaar
        $$('.adhOTPbtn').on('click',function(){
          if (!$$('#adh-otp')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyAadhaarOTP();
          }
        });
        function verifyAadhaarOTP() {
          var otp = $('#adh-otp').val();
          var data = 'otp='+otp+'&reference_id='+localStorage.getItem('reference_id')+'&aadhaarVerifyOTP=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying OTP...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                localStorage.removeItem('reference_id');
                app.popup.close('.sheet-OTP--aadhaar');
                $$('.aadhaarVerifyBtn').empty();
                $$('.aadhaarVerifyLable').empty();
                $$('.kyc__ver1').removeClass('hidden');
                $$('#aadhaarVerify').val('true');
                $$('#aadhaar').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //on keyup pan capitalize
        $$('#panNum').on('keyup', function() {
          var val = $$('#panNum').val();
          $$('#panNum').val(val.toUpperCase());
        });
        //verify pan card
        $$('.panVerifyBtn').on('click',function(){
          if (!$$('#panNum')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyPAN();
          }
        });
        function verifyPAN() {
          var pan = $('#panNum').val();
          var data = 'pan='+pan+'&panVerify=true+&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying PAN Card...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.panVerifyBtn').empty();
                $$('.panVerifyLable').empty();
                $$('.kyc__ver2').removeClass('hidden');
                $$('#panVerify').val('true');
                $$('#panNum').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //verify bank account
        $$('.bankVerifyBtn').on('click',function(){
          if (!$$('#bankacc')[0].checkValidity() && !$$('#ifsc')[0].checkValidity()) {
            console.log('Check Validity!');
          } else {
            verifyBank();
          }
        });
        function verifyBank() {
          var account_no = $('#bankacc').val();
          var ifsc = $('#ifsc').val();
          var data = 'account_no='+account_no+'&bankVerify=true+&ifsc='+ifsc+'&token='+localStorage.getItem('authToken');
          app.methods.request(
            baseURL+'/ajaxKYC.php',
            data,
            'json',
            function() { app.dialog.progress('Verifying Bank Account...'); $$('.button').addClass('disabled'); },
            function(data) {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              if(data.status == 'success') {
                $$('.bankVerifyBtn').empty();
                $$('.bankVerifyLable').empty();
                $$('.bankVerifyBtn').addClass('hidden');
                $$('.kyc__ver3').removeClass('hidden');
                $$('#bankVerify').val('true');
                $$('#bankacc').attr('readonly', true);
                $$('#ifsc').attr('readonly', true);
              }
              else if(data.status == 'error') {
                app.toast.show({
                  text: data.msg,
                  closeTimeout: 3000,
                });
              }
              else {
                app.toast.show({
                  text: 'Something went wrong, please try again',
                  closeTimeout: 3000,
                });
              }
            },
            function() {
              $$('.button').removeClass('disabled');
              app.dialog.close();
              app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
            }
          );
        }
        //get ifsc
        $$('#ifsc').on('keyup', function() {
          //capitalize ifsc
          var val = $$('#ifsc').val();
          $$('#ifsc').val(val.toUpperCase());
          $$('.bankBranchName').html('');
          $$('#branch').val('');
          if($$('#ifsc').val().length == 11) {
            $.getJSON('https://ifsc.razorpay.com/'+$$('#ifsc').val(), function(data) {
              $$('.bankBranchName').removeClass('hidden');
              $$('.bankBranchName').html('Branch: '+data.BRANCH);
              $$('#branch').val(data.BRANCH);
              $$('#bankname').val(data.BANK);
              $$('#bankname').attr('readonly', true);
              $$('.item-input-bank').addClass('item-input-focused');
              $$('.item-input-bank').addClass('item-input-with-value');
            });
          }
        })
        //input validation
        $$('.form4-submit').on('click', function() {
          var amount = localStorage.getItem('maxloan');
          var tenure = $$('#tenure').val();
          var addimg1 = $$('#addressimg1').prop('files')[0];
          var addimg2 = $$('#addressimg2').prop('files')[0];
          var pan = $$('#panNum').val();
          var panimg = $$('#panimg').prop('files')[0];;
          var bankAcc = $$('#bankacc').val();
          var IFSC = $$('#ifsc').val();
          var aadhaar = $$('#aadhaar').val();
          var bankname = $$('#bankname').val();
          var docType = $$('#kycDoc').val();
          var branch = $$('#branch').val();
          var passbook = $$('#passbook').prop('files')[0];
          var selfie = $$('#selfie').prop('files')[0];
          //business details
          var businessName = $$('#busName').val();
          var businessType = $$('#busType').val();
          var businessAddress = $$('#busAddress').val();
          var aadhaarVerify = $$('#aadhaarVerify').val();
          var panVerify = $$('#panVerify').val();
          var bankVerify = $$('#bankVerify').val();
          if($$('#gstnum').val()) {
            var gst = $$('#gstnum').val();
          } else {
            var gst = null;
          }
          if($$('#compPan').val()) {
            var compPan = $$('#compPan').val();
          } else {
            var compPan = null;
          }
          if($$('#companyPan').prop('files')[0]) {
            var companyPan = $$('#companyPan').prop('files')[0];
          } else {
            var companyPan = null;
          }
          var formData = new FormData();
          
          formData.append('form4', true);
          formData.append('token', localStorage.getItem('authToken'));
          formData.append('amount', amount);
          formData.append('tenure', tenure);
          formData.append('aadhaar', aadhaar);
          formData.append('docType', docType);
          formData.append('addressimg1', addimg1);
          formData.append('addressimg2', addimg2);
          formData.append('pan', pan);
          formData.append('panimg', panimg);
          formData.append('bankacc', bankAcc);
          formData.append('bankname', bankname);
          formData.append('ifsc', IFSC);
          formData.append('branch', branch);
          formData.append('passbook', passbook);
          formData.append('selfie', selfie);
          formData.append('businessName', businessName);
          formData.append('businessType', businessType);
          formData.append('businessAddress', businessAddress);
          formData.append('gst', gst);
          formData.append('compPan', compPan);
          formData.append('companyPan', companyPan);
          formData.append('verify1', aadhaarVerify);
          formData.append('verify2', panVerify);
          formData.append('verify3', bankVerify);
          var error = 0;
          for (var value of formData.values()) {
            if(value == '' || value == 'undefined') {
              error += 1;
            }
          }
          if(error == 0) {
            app.methods.post(
              baseURL+'ajaxKYC.php',
              formData,
              'json',
              function() {
                $$('.button').addClass('disabled');
                app.dialog.progress('Loading...');
              },
              function(data) {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.dialog.close();
                if(data.status == 'success') {
                  if(aadhaarVerify == 'true' && panVerify == 'true' && bankVerify == 'true') {
                    app.popup.open('.sheet-loan--applied');
                    $$('.sheet-loan--applied__title').html('KYC Submitted Successfully!');
                    $$('.sheet-loan--applied__text').html('Your KYC and Bank account has been verified, please see your loan status for further information.');
                    localStorage.setItem('amount', data.amount);
                    var view = app.views.current;
                    view.router.back('/',{force:true});
                  } else {
                    app.popup.open('.sheet-loan--applied');
                    $$('.loan__approvedIMG').attr('src','assets/icons/file.gif');
                    $$('.sheet-loan--applied__title').html('KYC Submitted Successfully!');
                    $$('.sheet-loan--applied__text').html('Your KYC and Bank account has been submitted, please wait up to 48 hours while we\'re verifying your information.');
                    localStorage.setItem('amount', data.amount);
                    var view = app.views.current;
                    view.router.back('/',{force:true});
                  }
                }
                else if(data.errorcode == 'applied') {
                  app.popup.open('.sheet-loan--applied');
                  $$('.sheet-loan--applied__title').html('You have already submitted your documents, please check your Loan Status!');
                  $$('.sheet-loan--applied__text').html('Please wait while we\'re verifying your information, you can conatct with us if you have any queries regarding your loan application.');
                  var view = app.views.current;
                  view.router.back('/',{force:true});
                }
                else {
                  app.toast.show({
                    text: 'Something went wrong, please try again',
                    closeTimeout: 3000,
                  });
                }
              },
              function() {
                $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'No internet connection!',
                closeTimeout: 3000,
              });
              }
            );
          }
          else {
            $$('.button').removeClass('disabled');
                app.dialog.close();
                app.toast.show({
                text: 'Please complete all the required steps!',
                closeTimeout: 3000,
              });
          }
          
        });
      }
    }
  },
  {
    path: '/beneficiary/',
    component: BeneficiaryPage,
    name: BeneficiaryPage,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        $$('.payment-block').hide();
        $$('.list__transferDet').empty();
        app.dialog.progress('Loading...');
        app.methods.getUserDataLoan(
          function(data) {
            var last = data.cardnumber.replace(/\d(?=\d{4})/g, "*");
            var val = last;
            var newval = '';
            val = val.replace(/\s/g, '');
            for(var i = 0; i < val.length; i++) {
                if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                newval = newval.concat(val[i]);
            }
            if(data.loanType == 'Personal Loan') {
              $$('.membership__cardTitle').html('Vanquis Loan Membership Card');
              $$('.card1').removeClass('hidden');
              $$('.pan').html(newval);
              $$('.pan').attr('data-gold',newval);
              $$('.name-on-card').html(data.name);
              $$('.name-on-card').attr('data-gold',data.name);
              $$('.date').html(data.valid);
              $$('.date').attr('data-gold',data.valid);
              $$('.date-2').html(data.validFrom);
              $$('.date-2').attr('data-gold',data.validFrom);
            } else {
              $$('.membership__cardTitle').html('Vanquis Platinum Loan Membership Card');
              $$('.card2').removeClass('hidden');
              $$('.pan').html(newval);
              $$('.pan').attr('data-silver',newval);
              $$('.name-on-card').html(data.name);
              $$('.name-on-card').attr('data-silver',data.name);
              $$('.date').html(data.valid);
              $$('.date').attr('data-silver',data.valid);
            }
            $$('.block__card').removeClass('hidden');
            if(data.order == '1') {
              $$('.card__status').html('Card Status: Active');
              $$('.card__status').removeClass('color-redx');
              $$('.card__status').addClass('color-green');
              $$('.toggle-list').removeClass('hidden');
            }
            var toggle = app.toggle.create({
              el: '.toggle2',
              on: {
                change: function () {
                  if(toggle.checked == true) {
                    var val = data.cardnumber;
                    var newval = '';
                    val = val.replace(/\s/g, '');
                    for(var i = 0; i < val.length; i++) {
                        if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                        newval = newval.concat(val[i]);
                    }
                    if(data.loanType == 'Personal Loan') {
                      $$('.pan').html(newval);
                      $$('.pan').attr('data-gold',newval);
                    } else {
                      $$('.pan').html(newval);
                      $$('.pan').attr('data-silver',newval);
                    }
                    
                  } else {
                    var last = data.cardnumber.replace(/\d(?=\d{4})/g, "*");
                    var val = last;
                    var newval = '';
                    val = val.replace(/\s/g, '');
                    for(var i = 0; i < val.length; i++) {
                        if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                        newval = newval.concat(val[i]);
                    }
                    if(data.loanType == 'Personal Loan') {
                      $$('.pan').html(newval);
                      $$('.pan').attr('data-gold',newval);
                    } else {
                      $$('.pan').html(newval);
                      $$('.pan').attr('data-silver',newval);
                    }
                  }
                }
              }
            });
            if(data.order != '1') {
              $$('.list__payProcessing').append('<ul>\
              <li>\
                  <div class="item-link item-content">\
                  <div class="item-media"><img src="assets/icons/rupee.png" width="40"/></div>\
                  <div class="item-inner">\
                  <div class="item-text text-color-gray">Vanquis Card Fees: <b class="text-color-red f-18">₹'+data.cardFees+'</b></div>\
                  <div class="item-text text-color-gray" style="font-size:10px;">The card fees is non-refundable under any circumstances.</div>\
                  <div class="item-text text-color-gray" style="font-size:10px;">The validity of our membership card will end on the specified date (card validity: 5 years).</div>\
                  <div class="item-text text-color-gray" style="font-size:10px;">Please read the terms & conditions carefully before buying the membership card.</div>\
                  <div class="item-text text-color-gray" style="font-size:10px;">By continuing, you agree to Buddy Finance\'s <a href="https://buddyfinance.in/terms-conditions" class="link external" target="_system">Terms & Conditions</a> and <a href="https://buddyfinance.in/privacy-policy" class="link external" target="_system">Privacy Policy</a>\
                  & <a href="https://buddyfinance.in/cards-tc" class="link external" target="_system">Cards\'s Terms</a> </div>\
                  <a href="/payment/" style="width: 50%;" class="col button button-fill color-blue bg-color-red margin-top btn-shine">Buy Now <i></i></a>\
                  </div>\
                  </div>\
              </li>\
            </ul>');
            }
            app.dialog.close();
          }
        );
        $$('input[name="lnINS"]').change(function() {
          console.log(this.checked);
          if(this.checked) {
            $$('.payment-block').show();
            $$('.page-content').scrollTop(10000, 400);
          } else {
            $$('.payment-block').hide();
          }
      });
      }
    }
  },
  {
    path: '/status/',
    component: loanStatusPage,
    name: status,
    on: {
      pageInit: function(e, page) {
        var app = this.app;
        app.dialog.progress('Loading...');
        app.methods.getUserData(function(data) {
          if(data.isLoggedin == 'true') {
            app.dialog.close();
            if(data.loanStatus == true) { //generate loan status
              app.methods.getUserDataLoan(function(data) {
                $$('.list__loanacnum').html(data.loanAcNum+' ('+data.loanType+')');
                $$('.list__loanstamnt').html(formatter.format(data.loanAmnt));
                $$('.list__loansttenure').html(data.tenure +' Months');
                if( data.emi != null) {
                  var emi = data.emi;
                }
                $$('.list__loanstemi').html(formatter.format(emi));
                $$('.list__loanstfees').html('₹'+data.fees);
                $$('.list__loanstrate').html(data.rateofinterest+'%'+' p.a. onwards');
                if(data.applied == true && data.approve != true) {
                  $$('.creditStore').empty();
                  $$('.creditStore').append('<div class="block text-align-center">\
                    <img src="assets/icons/file.gif" width="100" style="margin-top: 25px;"/>\
                    <div style="white-space: normal;" class="block-title block-title-medium text-align-center text-color-blue sheet-loan--applied__title">You\'ve submitted KYC for a '+data.loanType+' of '+formatter.format(data.loanAmnt)+'</div>\
                    <div class="item-title item-credit-txt"><span class="">Please wait while we\'re verifying your information, you can conatct with us if you have any queries regarding your loan application.</span></div>\
                  </div>');
                }
                if(data.applied == true && data.approve == true) {
                  $$('.block__card').removeClass('hidden');
                  var last = data.cardnumber.replace(/\d(?=\d{4})/g, "*");
                  var val = last;
                  var newval = '';
                  val = val.replace(/\s/g, '');
                  for(var i = 0; i < val.length; i++) {
                      if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                      newval = newval.concat(val[i]);
                  }
                  console.log(newval);
                  $$('.card__lastnum').html(newval);
                  $$('.cardholder__name').html(data.name);
                  $$('.card__expiry').html(data.expiry);
                  $$('.creditStore').empty();
                  var pre_approval = app.methods.getMaxloan(data.income);
                  if(data.order == '1') {
                    var last = data.cardnumber.replace(/\d(?=\d{4})/g, "*");
                    var val = last;
                    var newval = '';
                    val = val.replace(/\s/g, '');
                    for(var i = 0; i < val.length; i++) {
                        if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                        newval = newval.concat(val[i]);
                    }
                    $$('.creditStore').append('<div class="block text-align-center">\
                    <img src="assets/icons/verified.gif" class="img__loanApproved" width="100" />\
                    <div style="white-space: normal;" class="text-align-center text-color-blue sheet-loan--applied__title animate__animated animate__tada">Congratulations!</div>\
                    <div class="item-title item-credit-txt"><span class="">We will update you once we have a offer for you from our partner banks and NBFCs.</span></div>\
                    <div class="display-flex justify-content-center" style="gap: 15px;">\
                    <a href="/beneficiary/" type="submit" style="width:100%;margin-top:15px !important" class="button btn-shine button-fill bg-color-red margin-top m-auto">\
                      Vanquis Card <i></i> \
                    </a>\
                    <a href="#" type="submit" style="width:100%;margin-top:15px !important" class="button btn-shine button-fill bg-color-red margin-top m-auto">\
                      No Offers Yet\
                      <span class="badge color-redx">0</span><i></i>\
                    </a>\
                    </div>\
                    </div>');
                    $$('.block__membershipcard').removeClass('hidden');
                    if(data.order == '1') {
                      $$('.card__status').html('Card Status: Active');
                      $$('.card__status').removeClass('color-redx');
                      $$('.card__status').addClass('color-green');
                      $$('.toggle-list').removeClass('hidden');
                    }
                    var toggle = app.toggle.create({
                      el: '.toggle1',
                      on: {
                        change: function () {
                          if(toggle.checked == true) {
                            var val = data.cardnumber;
                            var newval = '';
                            val = val.replace(/\s/g, '');
                            for(var i = 0; i < val.length; i++) {
                                if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                                newval = newval.concat(val[i]);
                            }
                            if(data.loanType == 'Personal Loan') {
                              $$('.pan').html(newval);
                              $$('.pan').attr('data-gold',newval);
                            } else {
                              $$('.pan').html(newval);
                              $$('.pan').attr('data-silver',newval);
                            }
                          } else {
                            var last = data.cardnumber.replace(/\d(?=\d{4})/g, "*");
                            var val = last;
                            var newval = '';
                            val = val.replace(/\s/g, '');
                            for(var i = 0; i < val.length; i++) {
                                if(i%4 == 0 && i > 0) newval = newval.concat(' ');
                                newval = newval.concat(val[i]);
                            }
                            if(data.loanType == 'Personal Loan') {
                              $$('.pan').html(newval);
                              $$('.pan').attr('data-gold',newval);
                            } else {
                              $$('.pan').html(newval);
                              $$('.pan').attr('data-silver',newval);
                            }
                          }
                        }
                      }
                    });
                  } else {
                    if(data.kyc[0].isAadhaar == '1' && data.kyc[0].isPan == '1') {
                      var title = 'Congratulations!';
                      var offersText = 'Your KYC has been verified, you will get loan offers from Bank and NBFCs based on your eligibility.';
                      var img = 'verified.gif';
                    } else {
                      var title = 'KYC Under Progress!';
                      var offersText = 'Your KYC verification is under progress, you will get loan offers from Bank and NBFCs based on your eligibility.';
                      var img = 'file.gif';
                    }
                    $$('.creditStore').append('<div class="block text-align-center">\
                    <img src="assets/icons/'+img+'" class="img__loanApproved" width="100" />\
                    <div style="white-space: normal;" class="text-align-center text-color-blue sheet-loan--applied__title">'+title+' </div>\
                    <div class="item-title item-credit-txt"><span class=""> '+offersText+'</span></div>\
                    <div style="white-space: normal;" class="block-title block-title-medium text-align-center text-color-blue sheet-loan--applied__title animate__animated animate__tada">You\'re elligible of '+data.loanType+' upto '+formatter.format(pre_approval)+'</div>\
                    <a href="/beneficiary/" type="submit" style="width:80%" class="button button-fill color-blue button-large bg-color-red margin-top m-auto btn-shine">\
                      Unlock Offers <i></i>\
                    </a>\
                    </div>');
                  }
                  
                  $$('.block__loanDetails').removeClass('hidden');
                  $$('.block__bankDetails').removeClass('hidden');
                  $$('.block__kycDetails').removeClass('hidden');
                  $$('.block__feesDetails').removeClass('hidden');
                  $$('.block__moreDetails').removeClass('hidden');
                  //kyc details
                  if(data.kyc[0].isAadhaar == '1') {
                    $$('.list__aadhaar').html(data.kyc[0].aadhaar+'<i class="icon f7-icons text-color-green" style="font-size: 20px;top:-1px;right:-5px">checkmark_alt_circle_fill</i>');
                  } else {
                    $$('.list__aadhaar').html(data.kyc[0].aadhaar+'<i class="icon f7-icons text-color-redx" style="font-size: 20px;top:-1px;right:-5px">exclamationmark_circle_fill</i>');
                  }
                  if(data.kyc[0].isPan == '1') {
                    $$('.list__PAN').html(data.kyc[0].pan+'<i class="icon f7-icons text-color-green" style="font-size: 20px;top:-1px;right:-5px">checkmark_alt_circle_fill</i>');
                  } else {
                    $$('.list__PAN').html(data.kyc[0].pan+'<i class="icon f7-icons text-color-redx" style="font-size: 20px;top:-1px;right:-5px">exclamationmark_circle_fill</i>');
                  }
                  //bank details
                  $$('.list__bankName').html(data.bankname);
                  if(data.kyc[0].isBank == '1') {
                    $$('.list__bankAcNum').html(data.account+'<i class="icon f7-icons text-color-green" style="font-size: 20px;top:-1px;right:-5px">checkmark_alt_circle_fill</i>');
                  } else {
                    $$('.list__bankAcNum').html(data.account+'<i class="icon f7-icons text-color-redx" style="font-size: 20px;top:-1px;right:-5px">exclamationmark_circle_fill</i>');
                  }
                  $$('.list__ifsc').html(data.ifsc);
                  $$('.list__branch').html(data.branchName);
                  $('.img__loanApproved').trigger('click');
                  app.methods.request(
                    baseURL+'getEMI.php',
                    {token: localStorage.getItem('authToken')},
                    'html',
                    function() {
                      app.dialog.progress('Loading...');
                    },
                    function(data) {
                      app.dialog.close();
                      $$('#emi-popup .emi__block').html(data);
                    },
                    function() {
                      app.dialog.close();
                      app.toast.show({
                        text: 'No internet connection!',
                        closeTimeout: 3000,
                      });
                    }
                  );
                  //get Fees
                  if(data.allFees != false) {
                    $$('.listFees__li').empty();
                    var listFees__li = '<li>\
                      <div class="item-content">\
                        <div class="item-inner">\
                          <div class="item-title text-color-gray f-12"><b>Service Charge</b></div>\
                          <div class="item-after text-color-blue f-12">₹'+data.fees+'</div>\
                        </div>\
                      </div>\
                    </li>';
                    $.each(data.allFees, function(key, val){
                      listFees__li += '<li>\
                        <div class="item-content">\
                          <div class="item-inner">\
                            <div class="item-title text-color-gray f-12"><b>'+val[0]+'</b></div>\
                            <div class="item-after text-color-blue f-12">₹'+val[1]+'</div>\
                          </div>\
                        </div>\
                      </li>';
                    })
                    $$('.listFees__li').append(listFees__li);
                  } else {
                    $$('.listFees__li').empty();
                    $$('.listFees__li').append('<li>\
                      <div class="item-content">\
                        <div class="item-inner">\
                          <div class="item-title text-color-gray f-12"><b>Service Charge</b></div>\
                          <div class="item-after text-color-blue f-12">₹'+data.fees+'</div>\
                        </div>\
                      </div>\
                    </li>');
                  }
                  // document.querySelector(".img__loanApproved").addEventListener("click", function (e) {
                  //   party.confetti(this,{
                  //     count: party.variation.range(20, 60),
                  //     size: party.variation.range(0.8, 1.2),
                  //   });
                  // });
                }
              })
            } else {
              $$('.block__loanDetails').hide();
              $$('.card__applyLoanLinkx').attr('href','/form1/');
              $$('.card__applyLoanLinkx').addClass('hidden');
              $$('.card__applyLoanTXT').html('You can now apply for a loan');
            }
          } else {
            $$('.block__loanDetails').hide();
            app.dialog.close();
          }
        });
        // Create dynamic Sheet
        var dynamicSheet = app.sheet.create({
          content: '<div class="sheet-modal">\
                      <div class="toolbar">\
                        <div class="toolbar-inner">\
                          <div class="left"></div>\
                          <div class="right">\
                            <a class="link sheet-close">Done</a>\
                          </div>\
                        </div>\
                      </div>\
                      <div class="sheet-modal-inner">\
                        <div class="block">\
                          <p>Sheet created dynamically.</p>\
                          <p><a href="#" class="link sheet-close">Close me</a></p>\
                        </div>\
                      </div>\
                    </div>',
          // Events
          on: {
            open: function (sheet) {
              console.log('Sheet open');
            },
            opened: function (sheet) {
              console.log('Sheet opened');
            },
          }
        });
        // Open dynamic sheet
        $$(document).on('.view__LoanDetails','click', function () {
          // Close inline sheet before
          app.sheet.close('.my-sheet');
          // Open dynamic sheet
          dynamicSheet.open();
        });
      }
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
    on: {
      pageInit: function() {
        $$('.url').html(window.location);
      }
    }
  },
];

export default routes;